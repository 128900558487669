// localStorage
export const localSet = (key: string, data: any) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(data))
  }
}

export const localGet = (key: string) => {
  if (typeof window !== 'undefined') {
    const value = localStorage.getItem(key)
    if (value) {
      try {
        return JSON.parse(value)
      } catch (e) {
        return value
      }
    }
    return ''
  }
  return ''
}

export const localRemove = (key: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.removeItem(key)
  }
}

// SessionStorage
export const sessionSet = (key: string, data: any) => {
  sessionStorage.setItem(key, JSON.stringify(data))
}

export const sessionGet = (key: string) => {
  const value = sessionStorage.getItem(key)
  if (value) {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }
  return ''
}

export const sessionRemove = (key: string) => {
  sessionStorage.removeItem(key)
}
