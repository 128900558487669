import { on, postEvent } from '@telegram-apps/sdk'

export function openLink(link: string) {
  if (!link) return
  if (link.startsWith('https://t.me/')) {
    postEvent('web_app_open_tg_link', { path_full: link.slice(12) })
  } else {
    postEvent('web_app_open_link', { url: link })
  }
}

export function setupBack(callback: () => void) {
  postEvent('web_app_setup_back_button', { is_visible: true })
  on('back_button_pressed', () => {
    callback && callback()
    postEvent('web_app_setup_back_button', { is_visible: false })
  })
}

export function setupColor(color: string) {
  postEvent('web_app_set_background_color', { color: `#${color}` })
  postEvent('web_app_set_header_color', { color: `#${color}` })
}
