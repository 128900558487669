'use client'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'

const queryCache = new QueryCache({
  onError: (error) => {
    // 处理 error 的情况
  },
  onSuccess: (data) => {
    // 处理 success 的情况
  },
})

const mutationCache = new MutationCache({
  onError: (error) => {
    // 处理 error 的情况
  },
  onSuccess: (data) => {
    // 处理 success 的情况
  },
  onMutate: (newData) => {
    // 处理突变之前的情况
  },
})

const defaultOptions = {
  queries: {
    staleTime: Infinity,
  },
}

const queryClient = new QueryClient({
  mutationCache,
  queryCache,
  defaultOptions,
})

export default function QueryProvider({ children }: PropsWithChildren) {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
