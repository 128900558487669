import { shake } from './shake'
import { useGlobalStore } from '@/stores/global'

export function showToast(
  type: 'success' | 'fail' | 'warning' | 'none',
  message: string | HTMLElement,
) {
  const { isInApp } = useGlobalStore.getState()
  if (type === 'success' && isInApp) {
    shake('heavy')
  }
  if (typeof window !== 'undefined') {
    const toast = document.createElement('div')
    toast.classList.add('toast', `toast-${type}`)
    if (message instanceof HTMLElement) {
      toast.append(message)
    } else {
      toast.textContent = message
    }
    document.body.append(toast)
    setTimeout(
      () => {
        toast.remove()
      },
      type === 'fail' ? 8000 : 3000,
    )
  }
}
