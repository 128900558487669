import { request } from '@/shares/request'
export const getToken = {
  fetcher: ({ initData }: { initData: string }) =>
    request(`/operation/tg_claim/login`, {
      method: 'post',
      data: {
        init_data: initData,
      },
    }),
  key: 'getToken',
}

export const getPageInfo = {
  fetcher: ({ app }: { app: string }) =>
    request(`/operation/tg_claim/getWelcomePageInfo`, {
      method: 'post',
      data: {
        app,
      },
    }),
  key: 'getPageInfo',
}

interface TONProof {
  address: string
  network: string
  proof: Proof
  public_key: string
}

interface Proof {
  timestamp: number
  domain: {
    lengthBytes: number
    data: string
  }
  payload: string
  signature: string
  state_init: string
}

interface ClaimReward {
  app: string
  wallet_address: string
  ton_proof: TONProof
}

export const claimReward = {
  fetcher: ({ app, wallet_address, ton_proof }: ClaimReward) =>
    request(`/operation/tg_claim/claimReward`, {
      method: 'post',
      data: {
        app,
        wallet_address,
        ton_proof,
      },
    }),
  key: 'claimReward',
}
