import { on } from '@telegram-apps/sdk'
import { useGlobalStore } from '@/stores/global'
import { getToken, getPageInfo } from '@/apis'
import { localRemove, localSet } from '@/shares/storage'

export const getJwtToken = async () => {
  try {
    const { launchParams } = useGlobalStore.getState()
    if (!launchParams) return
    const res: any = await getToken.fetcher({
      initData: launchParams?.initDataRaw || '',
    })
    console.log('res:', res)
    if (res.code === 3313) {
      // 鉴权失败
      localRemove('jwt')
      useGlobalStore.setState({ jwt: '', jwtOverride: true })
    } else {
      localSet('jwt', res.jwt_token)
      useGlobalStore.setState({ jwt: res.jwt_token, jwtOverride: false })
    }
  } catch (error) {
    console.log('error:', error)
  }
}

export const getPageData = async (app: string) => {
  try {
    const res: any = await getPageInfo.fetcher({
      app,
    })
    console.log('res:', res)
    useGlobalStore.setState({ pageInfo: res })
  } catch (error) {
    console.log('error:', error)
  }
}

export function setupSafeAreaInset() {
  if (typeof window !== 'undefined') {
    document?.documentElement.style.setProperty('--safe-area-inset-bottom', '0px')
    on('viewport_changed', (payload) => {
      if (payload.is_expanded && payload.is_state_stable) {
        document?.documentElement?.style.setProperty(
          '--safe-area-inset-bottom',
          `${window.innerHeight - payload.height}px`,
        )
      }
    })
  }
}
