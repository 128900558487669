import catsLogo from '@/assets/images/token/cats_logo.webp'
// 获取环境配置
export const isDev = process.env.BUILD_TYPE !== 'build:pro'
export const HOST_API = process.env.HOST_API || 'https://api-gateway-go-web.bitkeep.zone'

// TODO: 修改正式bot信息
export const botUrl = `https://t.me/${isDev ? 'claim_tesy1111_bot' : 'claim_bot'}`
export const appUrl = `https://t.me/${isDev ? 'claim_test1111' : 'claim'}`

// 活动配置
export interface ActiveItem {
  path: string // 路由
  tokenName: string // 代币名称
  appName: string // 与后端约定的应用名称
  tokenIcon: string
  needLinePoints: boolean // 是否需要积分行
}

export const activeList: ActiveItem[] = [
  {
    path: '/cats',
    tokenName: 'CATS',
    appName: 'CATS',
    tokenIcon: catsLogo.src,
    needLinePoints: true,
  },
]

export const tonScanTx = 'https://tonviewer.com/transaction/'
