import { postEvent } from '@telegram-apps/sdk'
import { useGlobalStore } from '@/stores/global'

export function shake(style: 'light' | 'medium' | 'heavy' | 'rigid' | 'soft') {
  const { isInApp } = useGlobalStore.getState()
  if (isInApp) {
    postEvent('web_app_trigger_haptic_feedback', {
      type: 'impact',
      impact_style: style,
    })
  }
}
