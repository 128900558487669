import axios, { AxiosInstance } from 'axios'

import { HOST_API } from '@/constants'
import { localGet, localRemove } from '@/shares/storage'
import { useGlobalStore } from '@/stores/global'
import { showToast } from '@/shares/feedback/showToast'

export const request: AxiosInstance = axios.create({
  baseURL: HOST_API,
  timeout: 10000,
})

request.interceptors.request.use(
  (config) => {
    const jwt = localGet('jwt')
    config.headers.jwttoken = `${jwt}`
    return config
  },
  (error) => {
    // do something with request error
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  (response) => {
    if (response?.data?.status === 0) {
      // 错误处理
      if (response.data.data?.code === 3313) {
        // 鉴权失败
        localRemove('jwt')
        useGlobalStore.setState({
          jwt: '',
          jwtOverride: true,
          isClaiming: false,
        })
        return Promise.reject(null)
      }
      const errObj: any = {
        3501: 'Already claimed', // 已经claim过了
        3502: 'Failed to retrieve, please try again later', // 获取锁失败
        3503: 'Failed to retrieve, please try again later', // 账号钱不够
        3504: 'Failed to retrieve, please try again later', // 不支持该APP
        3505: 'Signature verification failed', // Claim签名验证失败
      }
      if (Object.keys(errObj).includes(response.data.data?.code)) {
        showToast('none', errObj[response.data.data?.code])
        useGlobalStore.setState({
          isClaiming: false,
        })
        return Promise.reject(null)
      }
      return response.data.data
    }
    // TODO: handle error
    return undefined
  },
  (error) => {
    // do something with response error
    const msg = error.response.data.errorMsg || 'request failed'
    showToast('none', msg)
    return Promise.reject(error)
  },
)
