import { create } from 'zustand'
import { LaunchParams } from '@telegram-apps/sdk'

interface GlobalState {
  isInApp: boolean
  appName: string
  launchParams: LaunchParams | null
  jwt: string
  jwtOverride: boolean // jwt是否已过期
  pageInfo: PageInfoResponse | undefined
  isClaiming: boolean // 是否正在调用claim接口
}
export const useGlobalStore = create<GlobalState>((set) => ({
  isInApp: false,
  appName: '',
  launchParams: null,
  jwt: '',
  jwtOverride: false,
  pageInfo: undefined,
  isClaiming: false,
}))
